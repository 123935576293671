import { MerchantMoeChainId } from 'constants/chains'
import { Point } from 'types/pool'

import { mETH, USDTe, WETH } from './swap'

export const getPoolPoints = (
  tokenX: string,
  tokenY: string,
  type: 'v1' | 'v2'
): Point[] => {
  const tX = tokenX.toLowerCase()
  const tY = tokenY.toLowerCase()

  const mETHAddress = mETH[MerchantMoeChainId.MANTLE].address.toLowerCase()
  const wETHAddress = WETH[MerchantMoeChainId.MANTLE].address.toLowerCase()
  const usdtAddress = USDTe[MerchantMoeChainId.MANTLE].address.toLowerCase()
  const usdeAddress = '0x5d3a1Ff2b6BAb83b63cd9AD0787074081a52ef34'.toLowerCase()
  const fBTCAddress = '0xC96dE26018A54D51c097160568752c4E3BD6C364'.toLowerCase()
  const wBTCAddress = '0xCAbAE6f6Ea1ecaB08Ad02fE02ce9A44F09aebfA2'.toLowerCase()

  const points: Point[] = []

  if (
    (tX === mETHAddress && tY === usdtAddress) ||
    (tX === usdtAddress && tY === mETHAddress)
  ) {
    switch (type) {
      case 'v1':
        points.push('powder_v1')
        break
      case 'v2':
        points.push('powder_v2_volatile')
        break
    }
  } else if (
    (tX === wETHAddress && tY === mETHAddress) ||
    (tX === mETHAddress && tY === wETHAddress)
  ) {
    switch (type) {
      case 'v1':
        points.push('powder_v1')
        break
      case 'v2':
        points.push('powder_v2_stable')
        break
    }
  } else if (
    ((tX === mETHAddress && tY === usdeAddress) ||
      (tX === usdeAddress && tY === mETHAddress)) &&
    type === 'v2'
  ) {
    points.push('powder_v2_volatile')
    points.push('ethena_30x_volatile')
  } else if (
    ((tX === mETHAddress && tY === fBTCAddress) ||
      (tX === fBTCAddress && tY === mETHAddress)) &&
    type === 'v2'
  ) {
    points.push('powder_v2_volatile')
    points.push('fbtc_4x')
  }

  if (
    (tX === wBTCAddress && tY === fBTCAddress) ||
    (tX === fBTCAddress && tY === wBTCAddress)
  ) {
    points.push('fbtc_2x')
  }

  return points
}
